body {
  background-color: #111111;
  color: #cea223;
  font-family: "Great Vibes", cursive;
}

.App {
  text-align: center;
}

.App h1 {
  font-size: 40px;
  height: 2em;
}

.App h2,
h3 {
  font-size: 20px;
}

.link {
  font-family: "Great Vibes", cursive;
  color: #cea223;
  background-color: #101010;
  border: 0px;
  font-size: 30px;
  font-variant: unicase;
  text-decoration: none;
}

.MuiButton-label {
  font-family: zapfino;
  margin-bottom: -12px;
  padding: 14px;
  text-transform: none;
}

button.image-gallery-icon {
  color: #cea223;
}

button.image-gallery-thumbnail.active {
  border: 4px solid#cea223;
}
