.aws {

    --slider-height-percentage: 100%;
    --slider-transition-duration: 356ms;
    --organic-arrow-thickness: 7px;
    --organic-arrow-border-radius: 0px;
    --organic-arrow-height: 57px;
    --organic-arrow-color: #c6ad42;
    --control-button-width: 15%;
    --control-button-height: 25%;
    --control-button-background: transparent;
    --control-bullet-color: transparent;
    --control-bullet-active-color: transparent;
    --loader-bar-color: #c6ad42;
    --loader-bar-height: 5px;
}