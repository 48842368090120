.details {
}

.details-item {
  width: 70%;
}

.details-schedule p {
  font-family: 'Lobster', cursive;
}
